.nav {
    position: absolute;
    width: 4%;
    height: 25%;

    top: 38%;
    left: 0%;

    z-index: 100;
    background-color: black;

    font-family: 'Hennessy';
    font-size: 0.6em;
    letter-spacing: .05rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.nav-home {
    width: 100%;

    flex: auto;
    background-image: url('./../../style/img/icons/home.png');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-back {
    width: 100%;

    flex: auto;
    background-image: url('./../../style/img/icons/back.png');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-void {
    width: 100%;

    flex: auto;
    background-repeat: no-repeat;
    background-size: 50%;
}