.discover {
    position: absolute;
    height: 100%;
    width: 100%;

    font-family: 'Hennessy';
    font-size: 1.4vw;
    letter-spacing: .2rem;

    background-color: black;
}

.discover-background {
    position: absolute;
    height: 100%;
    width: 100%;

    left: 0%;
    margin: 0 auto 0 auto;

    background-color: black;

    background-position: 0% center;
    background-size: cover;
    background-repeat: no-repeat;
}

.discover-partLeft {
    position: absolute;
    height: 82%;
    width: 45%;

    top: 10%;
    left: 2%;
    margin: 0 auto 0 auto;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.discover-bottle {
    position: absolute;
    height: 70%;
    width: 50%;

    top: 10%;
    left: 25%;
    margin: 0 auto 0 auto;

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.discover-smellButton {
    position: absolute;
    width: 50%;
    height: 10%;

    left: 25%;
    bottom: 0%;
    margin: 0 auto 0 auto;

    border: 1px solid white;

    text-align: center;
}

.discover-smellContent {
    position: absolute;
    width: 100%;

    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    font-size: 1.2vw;
    letter-spacing: 0.2rem;
    color: white;
    text-align: center;
}

.discover-partRight {
    position: absolute;
    height: 82%;
    width: 48%;

    top: 10%;
    right: 5%;
    margin: 0 auto 0 auto;
}

.discover-title {
    position: absolute;
    height: 25%;
    width: 100%;

    top: 0%;
}

.discover-title-content {
    position: absolute;
    width: 100%;

    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

}

.discover-yourMatch {
    margin: 0% 0% 0% -10%;

    color: white;
    font-size: 1.6vw;
    line-height: 3vw;
}

.discover-bottleName {
    margin: 0% 0% 0% -10%;

    color: white;
    font-size: 3.6vw;
}

.discover-content {
    position: absolute;
    height: 75%;
    width: 100%;

    top: 30%;
}

.discover-experience {
    position: absolute;
    width: 100%;
    height: 12vw;

    bottom: 0%;
}

.discover-adj {
    margin: 0% 0% 0% 0%;

    color: white;
    font-size: 100%;
}

.discover-adjDesc {
    margin: 1vw 0% 2vw 0%;

    color: white;
    font-family: 'Century Gothic';
    font-size: 90%;
    line-height: 200%;
    letter-spacing: .18rem;
}

.discover-adjDescTips {
    margin: 0.6vw 0% 3vw 0%;

    color: white;
    font-family: 'Century Gothic';
    font-size: 90%;
    line-height: 160%;
    letter-spacing: .1rem;
}

.discover-adjDescBold {
    font-family: 'Hennessy';
    color: white;
    font-size: 100%;
    line-height: 150%;
    letter-spacing: .1rem;
}

.discover-adjPictos {
    width: 100%;

    margin: 0.6vw 0% 0% 0%;

    color: white;
    font-family: 'Century Gothic';
    font-size: 90%;
    line-height: 160%;
    letter-spacing: .1rem;
}

.discover-pictos {
    display: inline-block;
    width: 10%;
    height: 4vw;

    margin: 0% -1% 0% -1%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.discover-pictosDesc {
    display: inline-block;
    height: 2vw;

    margin: 0% 0% 0% 2%;

    transform: translateY(-30%);
    -ms-transform: translateY(-30%);

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    line-height: 100%;
    letter-spacing: .1rem;
}

.discover-adjPunchline {
    position: absolute;
    margin: 0.6vw 0% 1vw 0%;

    bottom: 0%;;
    color: white;
    font-size: 100%;
    line-height: 150%;
    letter-spacing: .1rem;
}

.discover-partBottom {
    position: absolute;
    width: 40%;
    height: 12%;

    left: 40%;
    bottom: 0%;

    background-color: #1C1919;
    line-height: 1.6vw;
}

.discover-partBottomLeft {
    position: absolute;
    width: 40%;
    height: 100%;

    left: 5%;
}

.discover-partBottomRight {
    position: absolute;
    width: 48%;
    height: 100%;

    right: 5%;
}

.discover-partBottomDelimit {
    position: absolute;
    height: 60%;
    width: 2px;

    left: 0%;
    top: 20%;

    background-color: white;
}

.discover-recommendation {
    position: absolute;
    width: 50%;

    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    font-size: 1.2vw;
    color: white;
}

.discover-redeemGift {
    position: absolute;
    width: 50%;

    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    font-size: 1.2vw;
    color: white;
}

.discover-gift {
    position: absolute;
    height: 60%;
    width: 50%;

    top: 20%;
    right: 0%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.2vw;

    border: 1px solid white;
    color: white;
}

.discover-giftImg {
    position: absolute;
    height: 50%;
    width: 25%;

    top: 50%;
    left: 0%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    background-image: url('./../../style/img/pictos/neat.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 65%;
}

.discover-giftContent {
    position: absolute;
    width: 75%;

    top: 50%;
    left: 25%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.discover-partBottomRightText {
    position: absolute;
    width: 50%;
    min-height: 2%;

    top: 50%;
    left: 6%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    font-size: 1.2vw;
    color: white;

}

.discover-partBottomSameFlavourFamily {
    position: absolute;
    width: 35%;
    height: 8vw;

    bottom: 0%;
    /* -ms-transform: translateY(-50%);
    transform: translateY(-50%); */

    text-align: center;
    font-size: 1.2vw;
    color: white;

    border: 1px solid white;
}

.discover-partBottomSurpriseMe {
    position: absolute;
    width: 35%;
    height: 8vw;

    bottom: 0%;
    left: 40%;
    /* -ms-transform: translateY(-50%);
    transform: translateY(-50%); */

    text-align: center;
    font-size: 1.2vw;
    color: white;

    border: 1px solid white;
}

.discover-partBottomSameFlavourFamilyImg {
    position: absolute;
    height: 100%;
    width: 50%;

    top: 0%;
    left: 50%;

    background-repeat: no-repeat;
    background-size: auto 98%;
    background-position: right;
}

.discover-partBottomSameFlavourFamilyText {
    position: absolute;
    width: 50%;

    top: 50%;
    transform: translateY(-50%);

    margin: 0 5% 0 10%;

    font-size: 1vw;

    text-align: left;
}

.discover-partBottomSurpriseMeImg {
    position: absolute;
    height: 100%;
    width: 50%;

    top: 0%;
    left: 50%;

    background-repeat: no-repeat;
    background-size: auto 98%;
    background-position: right;
}

.discover-partBottomSurpriseMeText {
    position: absolute;
    width: 50%;

    top: 50%;
    transform: translateY(-50%);

    margin: 0 5% 0 10%;

    font-size: 1vw;

    text-align: left;
}

.discover-skip {
    display: flex;
    position: absolute;
    width: 10%;

    bottom: 5%;
    right: 5%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: all;
    cursor: pointer;
}

.discover-skip-label {
    flex: 1;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: all;
    cursor: pointer;
}

.discover-validationOptsValid {
    display: inline-block;
    width: 50%;

    text-align: center;
    vertical-align: top;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: white;

    font-size: 1.2vw;
}

.discover-validationOptsValidContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 5% 0% 5% 5%;
    vertical-align: top;

    color: black;
}