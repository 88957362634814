.loadingBar-container {
    position: absolute;
    height: 3px;
    width: 60%;

    bottom: 15%;
    left: 20%;

    border-radius: 3px;
    background-color: white;
    z-index: 300;

    pointer-events: none;
}

.loadingBar-content {
    position: absolute;
    height: 100%;
    width: 0%;
    max-width: 100%;

    border-radius: 3px;
    background-color: rgb(81, 216, 216);
    transition: all 0.1s;
}