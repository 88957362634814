.forfurther {
  position: absolute;
  height: 100%;
  width: 100%;

  top: 0%;

  color: white;
  font-family: 'Hennessy';
  font-size: 2.2vw;
  line-height: 3vw;
  letter-spacing: .3rem;

  background-image: url('./../../style/img/backgrounds/languages.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.forfurther-logo {
  position: absolute;
  width: 40%;
  height: 30%;

  top: 5%;
  left: calc(50% - 20%);
  /* center - (width/2) */
  margin: 0 auto 0 auto;

  background-image: url('./../../style/img/logoHennessy_white.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.forfurther-thanks {
  position: absolute;
  width: 90%;

  z-index: 10;
  top: 35%;
  left: 5%;
  margin: 0 auto 0 auto;

  text-align: center;
}

.forfurther-recommand {
  position: absolute;
  height: 20%;
  width: 60%;

  bottom: 6vw;
  left: 20%;
  z-index: 100;

  text-align: center;
  color: white;
  font-size: 2.2vw;
}

.forfurther-reachOutPicto {
  flex: 3;
  height: 70%;

  transform: translateY(-15%);

  background-image: url('./../../style/img/pictos/neat.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 60% center;
}

.forfurther-reachOutText {
  flex: 7;
}

.forfurther-reachOut {
  display: flex;
  position: absolute;
  width: 80%;
  height: 20%;

  top: 40%;
  left: 10%;

  justify-content: flex-start;
  align-items: center;
  border: 2px solid white;

  font-size: 2.4vw;
  line-height: 2.4vw;
}

.forfurther-reachOutRequest {
  position: relative;
  font-size: 2vw;
}

.forfurther-reachOutDiscovery {
  font-size: 1.4vw;
  line-height: 1.4vw;
}

.rangeslider-horizontal {
  height: 3px;

  border-radius: 10px;
}

.rangeslider-container {
  position: relative;
  top: 35%;
}

.rangeslider {
  display: block;
  position: relative;
  width: 100%;

  top: 40%;

  background: rgb(253, 246, 235);
  background: linear-gradient(90deg, rgba(253, 246, 235, 0) 0%, rgba(255, 255, 255, 0) 4%, rgba(255, 255, 255, 1) 4%, rgba(255, 255, 254, 1) 96%, rgba(255, 255, 254, 0) 96%, rgba(255, 255, 255, 0) 100%);
  /* touch-action: none; */
}

.rangeslider__fill {
  display: block;
  height: 100%;
  top: 0;
}

.rangeslider__handle {
  display: inline-block;
  position: absolute;
  width: 4vw;
  height: 4vw;

  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  z-index: 5;
  border-radius: 4vw;
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;

  color: black;
}

.rangeslider__handle-label-off {
  display: none;
}

.rangeslider__handle-label {
  position: absolute;

  left: 50%;
  transform: translateX(-40%) translateY(0.35vw);

  color: black;
}

.rangeslider__label-item {
  display: inline-block;
  position: absolute;

  transform: translate3d(-50%, 0%, 0);

  font-size: 2vw;
  cursor: pointer;
}

.forfurther-point1 {
  position: absolute;
  height: 1vw;
  width: 1vw;

  left: 5%;
  transform: translateX(-150%) translateY(-50%);

  border: 1px solid white;
  border-radius: 1vw;

  z-index: 0;
}

.forfurther-point2 {
  position: absolute;
  height: 1vw;
  width: 1vw;

  right: 5%;
  transform: translateX(+150%) translateY(-50%);

  border: 1px solid white;
  border-radius: 1vw;

  z-index: 0;
}

.thanks {
  position: absolute;
  height: 100%;
  width: 100%;

  top: 0%;

  color: white;
  font-family: 'Hennessy';
  font-size: 2.2vw;
  line-height: 3vw;
  letter-spacing: .3rem;

  background-image: url('./../../style/img/backgrounds/languages.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.thanks-text {
  position: absolute;
  height: 20%;
  width: 60%;

  text-align: center;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  line-height: 3.4vw;
}

.thanks-valid {
  height: 100%;

  margin: 5% 0 0 0;

  background-image: url('../../style/img/valid.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}