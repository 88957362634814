.step {
    position: absolute;
    width: 5%;
    height: 25%;

    top: 38%;
    right: 0%;

    z-index: 200;

    font-family: 'Hennessy';
    font-size: 0.6em;
    letter-spacing: .05rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    pointer-events: none;
}

.step-number {
    width: 100%;

    flex: auto;

    color: rgba(255, 255, 255, 0.6);

    font-size: 1.6vw;

    text-align: center;
}

.step-number-content {
    position: relative;    
    top: 50%;

    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.step-focus {
    color: rgba(255, 255, 255, 1);
}

.step-delimit-container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.step-delimit-1 {
    position: absolute;
    width: 40%;
    height: 2px;

    top: 33%;
    left: 30%;

    background-color: white;
}

.step-delimit-2 {
    position: absolute;
    width: 40%;
    height: 2px;

    bottom: 33%;
    left: 30%;

    background-color: white;
}