.flavour {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.9);
    font-family: 'Hennessy';
}

.step1 {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;

    color: white;
    font-family: 'Hennessy';
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: .2rem;
}

.step1-selectFlavour {
    position: absolute;
    width: 90%;

    z-index: 10;
    top: 60%;
    left: 5%;
    margin: 0 auto 0 auto;

    text-align: center;
    letter-spacing: .2rem;
}

.step1-flavourLeft {
    position: absolute;
    height: 100%;
    width: 33.4%;

    left: 0%;
    margin: 0 auto 0 auto;

    background-image: url('./../../style/img/backgrounds/flavours/flavour_1.jpg');
    background-position: 40% center;
    background-size: cover;
    background-repeat: no-repeat;
}

.step1-flavourCenter {
    position: absolute;
    height: 100%;
    width: 33.4%;

    left: 33.4%;
    margin: 0 auto 0 auto;

    background-image: url('./../../style/img/backgrounds/flavours/flavour_2.jpg');
    background-position: 62% center;
    background-size: cover;
    background-repeat: no-repeat;
}

.step1-flavourRight {
    position: absolute;
    height: 100%;
    width: 33.4%;

    right: 0%;
    margin: 0 auto 0 auto;

    background-image: url('./../../style/img/backgrounds/flavours/flavour_3.jpg');
    background-position: 30% center;
    background-size: cover;
    background-repeat: no-repeat;
}

.step1-flavourVideoContainer {
	position: absolute;
	height: 100%;
	width: 100%;

    overflow: hidden;
}

.step1-flavourVideo {
    position: absolute;
	aspect-ratio: 912/1824;

	z-index: 2000;
	transform-origin: center center;
}

.step1-flavourVideoPlay {
    position: absolute;
    width: 20%;
    aspect-ratio: 1/1;

    top: 45%;
    left: 40%;

    z-index: 20;

    background-image: url('./../../style/img/icons/play.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.step1-flavour {
    position: absolute;
    width: 80%;

    left: 10%;
    bottom: 15%;
    margin: 0 auto 0 auto;

    text-align: center;
    font-size: 1.3vw;
    line-height: 1.3vw;
    letter-spacing: .2rem;
}

.step1-choose {
    position: absolute;
    width: 80%;

    top: 100%;
    left: 10%;
    margin: 0 auto 0 auto;

    text-align: center;
    border: 1px solid white;
}

.step1-chooseImg {
    position: absolute;
    height: 40%;
    width: 40%;

    top: 30%;
    left: 15%;

    background-image: url('./../../style/img/icons/like.png');
    background-repeat: no-repeat;
    background-size: contain;

    filter: invert(100%);
}

.step1-chooseContent {
    margin: 10% 10% 10% 30%;
}



.step2 {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;

    color: white;
    font-family: 'Hennessy';
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: .2rem;
}

.step2-selectSmell {
    position: absolute;
    width: 90%;

    z-index: 10;
    top: 60%;
    left: 5%;
    margin: 0 auto 0 auto;

    text-align: center;
    letter-spacing: .2rem;
}

.step2-selectSmell-line {
    display: inline-block;

    margin: 0 2vw 0 0;
}

.step2-smell1 {
    position: absolute;
    height: 100%;
    width: 25%;

    left: 0%;
    margin: 0 auto 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
}

.step2-smell2 {
    position: absolute;
    height: 100%;
    width: 25%;

    left: 25%;
    margin: 0 auto 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
}

.step2-smell3 {
    position: absolute;
    height: 100%;
    width: 25%;

    right: 25%;
    margin: 0 auto 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
}

.step2-smell4 {
    position: absolute;
    height: 100%;
    width: 25%;

    right: 0%;
    margin: 0 auto 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
}

.step2-smell {
    position: absolute;
    width: 80%;
    height: 20%;

    left: 10%;
    bottom: 15%;
    margin: 0 auto 0 auto;

    font-size: 1.2vw;
    text-align: justify;
}

.step2-smellText {
    position: absolute;
    width: 100%;
    height: 30%;

    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    text-align: center;

    line-height: 2vw;
    font-size: 1.4vw;
}

.step2-smellIcon {
    position: absolute;
    width: 30%;
    aspect-ratio: 1/1;

    top: 50%;
    left: 35%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

    background-image: url('./../../style/img/icons/smellNose.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    cursor: pointer;
    -webkit-tap-highlight-color:transparent;
}

.step2-smellText-content {
    position: relative;
    width: 100%;

    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    text-align: center;

    line-height: 2vw;
    font-size: 1.4vw;
}

.step2-smellButton {
    position: absolute;
    width: 80%;
    height: 30%;

    top: 33%;
    left: 10%;
    margin: 0 auto 0 auto;

    border: 1px solid white;
}

.step2-smellButtonContainer {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;

    left: 50%;
    transform: translateX(-50%);

    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.step2-smellButtonImg {
    position: relative;
    height: 60%;
    aspect-ratio: 1/1;

    margin: 0% 3% 0% 0%;

    background-image: url('./../../style/img/icons/smell.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 70%;
}

.step2-smellButtonContent {
    position: relative;

    margin: 0% 0% 0% 3%;
}

.step2-choose {
    position: absolute;
    width: 80%;
    height: 30%;

    bottom: 0%;
    left: 10%;
    margin: 0 auto 0 auto;

    border: 1px solid white;
}

.step2-chooseContainer {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;

    left: 50%;
    transform: translateX(-50%);

    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.step2-chooseImg {
    position: relative;
    height: 40%;
    aspect-ratio: 1/1;

    margin: 0% 3% 0% 0%;

    background-image: url('./../../style/img/icons/like.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 65%;

    filter: invert(100%);
}

.step2-chooseContent {
    position: relative;

    margin: 0% 0% 0% 3%;
}

.step2-popup {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;
    z-index: 100;

    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.9);

    font-size: 2vw;
    letter-spacing: .2rem;
}

.step2-popupContent {
    position: absolute;
    width: 80%;
    height: 70%;

    top: 50%;
    left: 10%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    text-align: center;
}

.step2-popupNext {
    display: flex;
    position: absolute;
    width: 10%;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: none;
}

.step2-popupNext-label {
    flex: 1;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: none;
}

.step2-popupDesc {
    position: absolute;
    width: 90%;

    top: 60%;
    margin-left: 5%;
    z-index: 100;

    color: white;
    font-size: 2vw;
    line-height: 3.5vw;
}

.step2-popupChooseImg {
    position: absolute;
    height: 30%;
    aspect-ratio: 1/1;

    top: 50%;
    left: 50%;
    margin: 0% 0% 0% 0%;
    transform: translateX(-50%) translateY(-100%);
    -ms-transform: translateX(-50%) translateY(-100%);

    background-image: url('./../../style/img/icons/heart.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.step2-validation {
    position: absolute;
    height: 100%;
    width: 100%;

    text-align: center;

    background-color: rgba(0, 0, 0, 0.9);
    z-index: 110;
}

.step2-validation-desc-line {
    position: absolute;
    width: 100%;
    height: 100px;

    top: 15%;
}

.step2-validationLeft {
    position: absolute;
    height: 120%;
    width: 27%;

    left: 10%;
    top: 22%;
    margin-top: 1.6vw;

    z-index: 120;
    text-align: center;
    color: #63C794;
}

.step2-validationCenter {
    position: absolute;
    height: 120%;
    width: 27%;

    left: 36.5%;
    top: 22%;
    margin-top: 1.6vw;

    z-index: 120;
    text-align: center;
    color: #63C794;
}

.step2-validationRight {
    position: absolute;
    height: 120%;
    width: 27%;

    right: 10%;
    top: 22%;
    margin-top: 1.6vw;

    z-index: 120;
    text-align: center;
    color: #63C794;
}

.step2-validationLeftContent {
    margin: 0 0 4% 0;
}

.step2-validationCenterContent {
    margin: 0 0 4% 0;
}

.step2-validationRightContent {
    margin: 0 0 4% 0;
}

.step2-validationPreshow {
    position: absolute;
    height: 40%;
    width: 80%;

    left: 10%;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.step2-validationPreshowText {
    position: absolute;
    text-align: center;
    width: 100%;

    top: 45%;
    font-size: 1.4vw;
}

.step2-validationOpts {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 78%;

    z-index: 120;
    text-align: center;
}

.step2-validationOptsRemove {
    display: inline-block;
    width: 12%;

    top: 100%;
    margin: 0% 2%;

    text-align: center;
    vertical-align: top;
    border: 1px solid rgba(255, 255, 255, 0.5);

    font-size: 1.2vw;
}

.step2-validationOptsRemoveContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 2% 0% 2% 5%;
    vertical-align: top;
}

.step2-validationOptsValid {
    display: inline-block;
    width: 12%;

    top: 100%;
    margin: 0% 2%;

    text-align: center;
    vertical-align: top;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: white;

    font-size: 1.2vw;
}

.step2-validationOptsValidContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 2% 0% 2% 5%;
    vertical-align: top;

    color: black;
}

.step3 {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;
    background-color: rgb(44, 7, 7);

    font-family: 'Hennessy';
    font-size: 2vw;
    line-height: 1.4vw;
    letter-spacing: .3rem;

    color: white;
}

.step3-explanations-elemLeft {
    display: inline-block;
    height: 35vw;
    width: 22%;

    margin: 2% 3% 3% 0%;

    background-repeat: no-repeat;
    background-size: cover;

    overflow-y: hidden;
}

.step3-explanations-elemRight {
    display: inline-block;
    height: 35vw;
    width: 22%;

    margin: 2% 0% 3% 3%;

    background-repeat: no-repeat;
    background-size: cover;

    overflow-y: hidden;
}

.step3-explanations-content {
    position: relative;
    width: 94%;

    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

    text-align: center;
    font-size: 1.7vw;
    line-height: 2.4vw;
}

.step3-skip {
    display: flex;
    position: absolute;
    width: 10%;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: none;
}

.step3-skip-label {
    flex: 1;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: none;
}

.bottleVideoContainer {
	position: absolute;
	height: 100%;
	width: 100%;

    overflow: hidden;
}

.bottleVideo {
    position: absolute;
	aspect-ratio: 2736/1824;

	transform-origin: center center;
}