.explanations {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;
    z-index: 205;

    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.9);

    font-size: 2vw;
    line-height: 1.4vw;
    letter-spacing: .3rem;
}

.explanations-content {
    position: absolute;
    width: 80%;

    top: 50%;
    left: 10%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.explanations-next {
    position: absolute;
    width: 10%;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    cursor: pointer;
}

.explanations-next-container {
    position: absolute;
    width: 20%;
    height: 12%;

    bottom: 5%;
    right: 5%;

    text-align: right;
    font-size: 1.2vw;
    color: white;


    z-index: 8000;
    cursor: pointer;
}

.explanations-step-container {
    display: inline-block;
}

.explanations-step-chip {
    display: inline-block;
    width: 3%;
    height: 3px;

    margin-bottom: 0.6vw;
    margin-left: 6%;

    background-color: white;
}

.explanations-step {
    display: inline-block;
    height: 8%;
    width: 90%;

    margin-top: 0%;
    margin-bottom: 1%;
    margin-left: 1%;
    z-index: 100;

    color: #63C794;
    font-size: 1.6vw;
}

.explanations-desc {
    position: inline-block;
    width: 95%;

    top: 8%;
    margin-left: 10%;
    z-index: 100;

    color: white;
    font-size: 2vw;
    line-height: 3.5vw;
}

.explanationsSpec-desc {
    position: absolute;
    width: 100%;

    text-align: center;
}

.explanations-objs {
    position: inline-block;
    height: 4vw;
    width: 100%;

    margin-top: 2vw;
    z-index: 100;
}

.explanations-play {
    display: inline-block;
    height: 100%;
    width: 26%;

    top: 100%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.4vw;
}

.explanations-playImg {
    display: inline-block;
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;

    margin: 0% 0% 0% 0%;

    background-image: url('./../../style/img/icons/playButton.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explanations-playContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 1% 0% 0% 8%;
    vertical-align: top;
}

.explanations-like {
    display: inline-block;
    height: 100%;
    width: 26%;

    top: 100%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.4vw;
}

.explanations-likeImg {
    display: inline-block;
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;

    margin: 0% 0% 0% 0%;

    background-image: url('./../../style/img/icons/likeButton.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explanations-likeContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 1% 0% 0% 8%;
    vertical-align: top;
}

.explanations-smell {
    display: inline-block;
    height: 100%;
    width: 26%;

    top: 100%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.4vw;
}

.explanations-smellImg {
    display: inline-block;
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;

    margin: 0% 0% 0% 0%;

    background-image: url('./../../style/img/icons/smell.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explanations-smellContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 1% 0% 0% 8%;
    vertical-align: top;
}

.explanations-and {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 0% 5% 0% 0%;
    vertical-align: top;

    color: #63C794;
    font-size: 3vw;
    font-family: 'Hennessy';
}

.explanations-watch {
    display: inline-block;
    height: 100%;
    width: 26%;

    top: 100%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.4vw;
}

.explanations-watchImg {
    display: inline-block;
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;

    margin: 0% 0% 0% 0%;

    background-image: url('./../../style/img/icons/watch.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explanations-watchContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 1% 0% 0% 8%;
    vertical-align: top;
}

.explanations-match {
    display: inline-block;
    height: 100%;
    width: 26%;

    top: 100%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.4vw;
}

.explanations-matchImg {
    display: inline-block;
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;

    margin: 0% 0% 0% 0%;

    background-image: url('./../../style/img/icons/matchButton.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explanations-matchContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 1% 0% 0% 8%;
    vertical-align: top;
}

.explanations-choose {
    display: inline-block;
    height: 100%;
    width: 35%;

    top: 100%;

    text-align: left;
    vertical-align: middle;

    font-size: 1.4vw;
}

.explanations-chooseImg {
    display: inline-block;
    position: relative;
    height: 100%;
    aspect-ratio: 1/1;

    margin: 0% 0% 0% 0%;

    background-image: url('./../../style/img/icons/likeButton.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.explanations-chooseContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 1% 0% 0% 8%;
    vertical-align: top;
}

.explanations-next {
    display: flex;
    position: absolute;
    width: 10%;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: all;
}

.explanations-next-label {
    flex: 1;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: all;
}

.arrow {
    flex: 1;

    margin-right: 1.2em;

    background-image: url('./../../style/img/icons/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;

    background-position: 100% center;
}

.explanations-validationOptsValid {
    display: inline-block;
    width: 40%;

    top: 100%;
    margin: 0% 2%;

    text-align: center;
    vertical-align: top;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: white;

    font-size: 1.2vw;
}

.explanations-validationOptsValidContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 3% 0% 3% 5%;
    vertical-align: top;

    color: black;
}