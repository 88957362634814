.smell {
    position: absolute;
    height: 100%;
    width: 100%;

    z-index: 200;

    background-color: rgba(0, 0, 0, 0.9);

    text-align: center;
    color: white;
    font-family: 'Hennessy';
    font-size: 2vw;
    line-height: 1.4vw;
    letter-spacing: .3rem;
}

.smell-visage {
    position: absolute;
    height: 100%;
    width: 100%;

    background-image: url('./../../style/img/backgrounds/visage.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% center;
}

.smell-center-content {
    position: absolute;
    width: 100%;
    height: 50%;

    top: 60%;

    pointer-events: none;
}

.smell-img {
    position: relative;
    height: 100%;
    width: 50%;

    margin: 0% 25% 0% 25%;
    vertical-align: center;

    background-image: url('./../../style/img/icons/smell.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.smell-content {
    position: relative;
    height: 50%;

    margin: 0% auto auto auto;
    vertical-align: top;
    color: white;
}

.smellVideoContainer {
	position: absolute;
	height: 100%;
	width: 100%;

    overflow: hidden;
}

.smellVideo {
    position: absolute;
	aspect-ratio: 2736/1824;

	transform-origin: center center;
}