.home {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.9);

    font-family: 'Hennessy';

    color: white;
}

.home-selectExp {
    position: absolute;
	width: 90%;

    z-index: 10;
    top: 15%;
    left: 5%;
    margin: 0 auto 0 auto;

    text-align: center;
    font-size: 2vw;
    letter-spacing: .2rem;
}

.home-expLeft {
    position: absolute;
    height: 100%;
    width: 50%;

    left: 0%;
    margin: 0 auto 0 auto;

    background-image: url('./../../style/img/backgrounds/home_1.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-expRight {
    position: absolute;
    height: 100%;
    width: 50%;

    right: 0%;
    margin: 0 auto 0 auto;

    background-image: url('./../../style/img/backgrounds/home_2.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-expTitle {
    flex: 1;
    position: absolute;
    height: 60%;
    width: 30%;

    top: 25%;
    left: 25%;
    padding-left: 5%;
    padding-right: 5%;

    border: 1px solid white;

    font-family: 'Century Gothic';
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    font-size: 2vw;
    letter-spacing: .2rem;
}

.home-expTitleScript {
    font-family: 'Hennessy';
    font-size: 3vw;
    letter-spacing: .2rem;
}