.simulator {
    position: absolute;
    height: 100%;
    width: 100%;

    z-index: 200;
    pointer-events: none;
}

.simulator-container {
    position: absolute;
    height: 64px;
    width: 90px;

    top: 1%;
    left: calc(50% - 50px);

    border: 5px solid rgba(13, 74, 109, 1);;
    background-color: rgba(27, 148, 218, 0.815);
}

.simulator-spray {
    display: inline-block;
    height: 10px;
    width: 10px;

    margin: 4px;

    background-color: chartreuse;
    border-radius: 5px;
}