@font-face {
	font-family: "Euclid Flex";
	src: url("../fonts/EuclidFlex.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Euclid Flex Bold";
	src: url("../fonts/Euclid\ Flex\ Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Euclid Flex Medium";
	src: url("../fonts/Euclid\ Flex\ Medium.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Euclid Flex Trial";
	src: url("../fonts/Euclid\ Flex\ Trial.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Cormorant";
	src: url("../fonts/Cormorant-Regular.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Cormorant Medium";
	src: url("../fonts/Cormorant-Medium.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Hennessy";
	src: url("../fonts/HennessySans1765_W.woff2") format("woff2");
	font-weight: normal;
}

@font-face {
	font-family: "Hennessy Script";
	src: url("../fonts/HennessyScript1765_W_It.woff2") format("woff2");
	font-weight: normal;
}

@font-face {
	font-family: "Hennessy Serif";
	src: url("../fonts/HennessySerif1765_W.woff2") format("woff2");
	font-weight: normal;
}

@font-face {
	font-family: "Century Gothic";
	src: url("../fonts/CenturyGothic.ttf") format("truetype");
	font-weight: normal;
}

#transition {
	position: absolute;
	height: 100%;
	width: 100%;

	z-index: 1000;

	background-color: black;
	transition: all 0.5s;
	pointer-events: none;
}

.app {
	background-image: url('../img/03_HE_MoodSelector-11.png');
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100vw;
	height: 69.491vw;
	/* 16/9 = 1.778 1640 / 2360*/
	max-height: 100vh;
	max-width: 143.90vh;
	/* height:width ratio = 9/16 = .5625  2360/1640*/
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	/* vertical center */
	left: 0;
	right: 0;
	/* horizontal center */
}

.btn {
	position: absolute;
	width: 16%;
	height: 8%;
	/*background-color: red;*/
	opacity: 0.3;
	cursor: pointer;
}

.btn-left {
	top: 60%;
	left: 10%;
}

.btn-middle {
	top: 60%;
	left: 42%;
}

.btn-right {
	top: 60%;
	left: 75%;
}

.standByVideoContainer {
	position: absolute;
	height: 100%;
	width: 100%;

	overflow: hidden;
}

.standByVideo {
	position: absolute;
	aspect-ratio: 2736/1824;

	z-index: 2000;
	transform-origin: center center;
}

.videoPointer {
	position: absolute;
	height: 8%;
	aspect-ratio: 1/1;

	left: 45%;
	top: 80%;
	transform: translateX(-50%);
	-ms-transform: translateX(-50%);

	background-image: url('./../../style/img/icons/touch.png');
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;

	z-index: 2000;
}

.videoPointer {
	offset-rotate: 0deg;

	offset-path: path("M18.45,58.46s52.87-70.07,101.25-.75,101.75-6.23,101.75-6.23S246.38,5.59,165.33,9.08s-15,71.57-94.51,74.56S18.45,58.46,18.45,58.46Z");
	animation: move 6s linear infinite;
}

@keyframes move {
	to {
		offset-distance: 100%;
	}
}