.header {
    position: absolute;
    height: 15%;
    width: 100%;

    z-index: 100;

    font-family: 'Hennessy';
    font-size: 1.2vw;
    letter-spacing: .05rem;
}

.header-logo {
    position: absolute;
	width: 40%;
	height: 100%;

    left: 5%;
    margin: 0 auto 0 auto;

	background-image: url('./../../style/img/logoHennessy_withoutarm_white.png');
	background-position: 0% center;
	background-size: contain;
	background-repeat: no-repeat;
}

.header-quit {
    position: absolute;
    width: 40%;
	height: 100%;

    top: calc(50% - 0.35em);
    right: 5%;
    margin: 0 auto 0 auto;
    text-align: end;

    color: white;
}