.cognac {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: black;
}

.c-step1 {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;

    color: white;
    font-family: 'Hennessy';
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: .2rem;

    background-color: black;
}

.c-step1-selectBottle {
    position: absolute;
    width: 90%;
    height: 10%;

    z-index: 10;
    top: 15%;
    left: 5%;
    margin: 0 auto 0 auto;

    text-align: left;
    letter-spacing: .2rem;
}

.c-step1-selectBottleContent {
    position: absolute;

    left: 50%;

    transform: translateX(-50%);

    white-space: nowrap;
}

.c-step1-chip {
    position: absolute;
    width: 4%;
    height: 3px;

    margin-top: 1.4vw;
    margin-left: -6%;

    background-color: white;
}

.c-step1-step {
    position: absolute;
    height: 8%;
    width: 90%;

    margin-top: 0%;
    margin-bottom: 1%;
    z-index: 100;

    color: #63C794;
    font-size: 1.6vw;
}

.c-step1-bottle0 {
    position: absolute;
    height: 100%;
    width: 20%;

    left: 0%;
    margin: 0 auto 0 auto;

    background-position: center 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step1-bottle1 {
    position: absolute;
    height: 100%;
    width: 20%;

    left: 20%;
    margin: 0 auto 0 auto;

    background-position: center 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step1-bottle2 {
    position: absolute;
    height: 100%;
    width: 20%;

    left: 40%;
    margin: 0 auto 0 auto;

    background-position: center 50%;
    background-size: cover;
	background-repeat: no-repeat;
}

.c-step1-bottle3 {
    position: absolute;
    height: 100%;
    width: 20%;

    left: 60%;
    margin: 0 auto 0 auto;

    background-position: center 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step1-bottle4 {
    position: absolute;
    height: 100%;
    width: 20%;

    left: 80%;
    margin: 0 auto 0 auto;

    background-position: center 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step1-bottleImg {
    position: absolute;
    width: 50%;
    height: 30%;

    top: 35%;
    left: 25%;

    background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.c-step1-bottle {
    position: absolute;
    width: 80%;

    left: 10%;
    bottom: 20%;
    margin: 0 auto 0 auto;

    text-align: center;
    font-size: 1.3vw;
    line-height: 1.8vw;
    letter-spacing: .2rem;
}

.c-step1-select {
    position: absolute;
    width: 90%;
    height: 120%;

    top: 100%;
    left: 5%;
    margin: 0 auto 0 auto;

    text-align: center;
    border: 1px solid white;
}

.c-step1-selectContainer {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;

    left: 50%;
    transform: translateX(-50%);

    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.c-step1-selectImg {
    position: relative;
    height: 40%;
    aspect-ratio: 1/1;

    margin: 0% 3% 0% 0%;

    background-image: url('./../../style/img/icons/like.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 65%;

    filter: invert(100%);
}

.c-step1-selectContent {
    position: relative;

    margin: 0% 0% 0% 3%;
}



.c-step2 {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;

    background-color: rgb(44, 7, 7);

    font-family: 'Hennessy';
    color: white;
    
    font-size: 2vw;
    line-height: 1.4vw;
    letter-spacing: .3rem;
}

.c-step2-skip {
    display: flex;
    position: absolute;
    width: 10%;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: none;
}

.c-step2-skip-label {
    flex: 1;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: none;
}

.c-step3 {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;
    
    background-color: rgb(44, 7, 7);

    font-family: 'Hennessy';
    font-size: 2vw;
    line-height: 1.4vw;
    letter-spacing: .3rem;

    color: white;
}

.c-step3-topPart {
    position: absolute;
    height: 40%;
    width: 100%;

    top: 0%;

    background-color: rgb(0, 0, 0);

    color: white;
}

.c-step3-topPartContent {
    position: absolute;
    width: 100%;

    top: 40%;

    text-align: center;
}

.c-step3-smellButton {
    /*position: absolute;
    width: 20%;
    height: 20%;

    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 0 auto;

    border: 1px solid white;
    font-size: 1.4vw;*/
    position: absolute;
    width: 30%;
    height: 20%;

    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 0 auto;

    text-align: center;
    border: 1px solid white;
    font-size: 1.4vw;
}

.c-step3-smellButtonInside {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;

    left: 50%;
    transform: translateX(-50%);

    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.c-step3-smellButtonImg {
    position: relative;
    height: 40%;
    aspect-ratio: 1/1;

    margin: 0% 3% 0% 0%;

    background-image: url('./../../style/img/icons/smell.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 65%;
}

.c-step3-smellButtonContent {
    position: relative;

    margin: 0% 0% 0% 3%;
}

.c-step3-bottomPart {
    position: absolute;
    height: 60%;
    width: 100%;

    top: 40%;

    background-color: rgb(0, 0, 0);

    color: white;
}

.c-step3-bottomPartLeft {
    position: absolute;
    height: 100%;
    width: 33.4%;

    left: 0%;
    margin: 0 auto 0 auto;

	background-position: 40% center;
	background-size: cover;
	background-repeat: no-repeat;

    overflow: hidden;
}

.c-step3-bottomPartCenter {
    position: absolute;
    height: 100%;
    width: 33.4%;

    left: 33.4%;
    margin: 0 auto 0 auto;

	background-position: 62% center;
	background-size: cover;
	background-repeat: no-repeat;

    overflow: hidden;
}

.c-step3-bottomPartRight {
    position: absolute;
    height: 100%;
    width: 33.4%;

    right: 0%;
    margin: 0 auto 0 auto;

	background-position: 30% center;
	background-size: cover;
	background-repeat: no-repeat;

    overflow: hidden;
}

.c-step3-flavour {
    position: absolute;
    width: 60%;
    height: 10%;

    left: 20%;
    bottom: 45%;
    margin: 0 auto 0 auto;

    font-size: 1.2vw;
    text-align: center;
}

.c-step3-flavourContent {
    position: absolute;
    width: 100%;

    margin: 0 auto 0 auto;

    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

    font-size: 1.2vw;
    text-align: center;
}

.c-step3-match {
    position: absolute;
    width: 100%;

    top: 50%;
    margin: 0 auto 0 auto;

    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
    line-height: 3vw;
    font-size: 1.2vw;
}

.c-step3-matchImg {
    position: absolute;
    height: 30%;
    width: 30%;

    top: 35%;
    left: 25%;

    background-image: url('./../../style/img/icons/match.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.c-step3-matchContent {
    margin: 5% 10% 5% 25%;
}

.c-step3-scent {
    position: absolute;
    width: 100%;
    height: 100%;

    background-position: 100% 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step3-validation {
    position: absolute;
    height: 100%;
    width: 100%;

    text-align: center;

    background-color: rgba(0, 0, 0, 0.9);
    z-index: 110;
}

.c-step3-validationLeft {
    position: absolute;
    height: 100%;
    width: 40%;

    left: 10%;
    top: 15%;
    margin-top: 1.6vw;

    z-index: 120;
    text-align: center;
    color: #63C794;
}

.c-step3-validationRight {
    position: absolute;
    height: 100%;
    width: 40%;

    right: 10%;
    top: 15%;
    margin-top: 1.6vw;

    z-index: 120;
    text-align: center;
    color: #63C794;
}

.c-step3-validationLeftContent {
    margin: 0 0 4% 0;
}

.c-step3-validationRightContent {
    margin: 0 0 4% 0;
}

.c-step3-validationPreshow {
    position: absolute;
    height: 50%;
    width: 80%;

    left: 10%;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.c-step3-validationPreshowText {
    position: absolute;
    text-align: center;
    width: 100%;

    top: 45%;
    font-size: 1.4vw;
}

.c-step3-validationOpts {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 80%;

    z-index: 120;
    text-align: center;
    line-height: 3vw;
}

.c-step3-validationOptsRemove {
    display: inline-block;
    width: 15%;

    top: 100%;
    margin: 0% 2%;

    text-align: center;
    vertical-align: top;
    border: 1px solid rgba(255, 255, 255, 0.5);

    font-size: 1.2vw;
}

.c-step3-validationOptsRemoveContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 0% 0% 0% 5%;
    vertical-align: top;
}

.c-step3-validationOptsValid {
    display: inline-block;
    width: 15%;

    top: 100%;
    margin: 0% 2%;

    text-align: center;
    vertical-align: top;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: white;

    font-size: 1.2vw;
}

.c-step3-validationOptsValidContent {
    display: inline-block;
    position: relative;
    height: 50%;

    margin: 0% 0% 0% 5%;
    vertical-align: top;

    color: black;
}

.c-step3-results {
    position: absolute;
    height: 100%;
    width: 100%;

    text-align: center;

    background-color: rgba(0, 0, 0, 0.9);
    z-index: 120;
    line-height: 3vw;
}

.c-step3-resultsContainer {
    position: absolute;
    height: 75%;
    width: 90%;

    top: 50%;
    left: 5%;
    margin: 0 auto 0 auto;
    transform: translateY(-50%);

	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step3-resultsContent {
    position: absolute;
    width: 90%;

    top: 50%;
    left: 5%;
    margin: 0 auto 0 auto;
    transform: translateY(-50%);

	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-step3-resultsOk {
    display: inline-block;
    width: 100%;

    top: 40%;

    text-align: center;

    z-index: 120;
}

.c-step3-resultsScent {
    display: block;
    height: 35vw;
}

.c-step3-resultsScentLeft {
    position: absolute;
    height: 35vw;
    width: 30%;

    left: 20%;

    z-index: 120;
    text-align: center;
}

.c-step3-resultsScentRight {
    position: absolute;
    height: 35vw;
    width: 30%;

    right: 20%;

    z-index: 120;
    text-align: center;
}

.c-step3-resultsScentLeftContent {
    margin: 0 0 0 0;

    font-size: 2vw;
}

.c-step3-resultsScentRightContent {
    margin: 0 0 0 0;

    font-size: 2vw;
}

.c-step3-resultsScentPreshow {
    position: absolute;
    height: 100%;
    width: 80%;

    left: 10%;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.c-step3-resultsScentPreshowText {
    position: absolute;
    text-align: center;
    width: 100%;

    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);

    text-align: center;
    font-size: 1.4vw;
}

.c-step3-resultsIndeed {
    margin: 0% 0% 0% 0%;
}

.c-step3-skip {
    display: flex;
    position: absolute;
    width: 10%;

    z-index: 99;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    line-height: 1.4vw;
    color: white;

    pointer-events: all;
    cursor: pointer;
}

.c-step3-popup-skip {
    display: flex;
    position: absolute;
    width: 10%;

    z-index: 101;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: all;
    cursor: pointer;
}

.c-step3-skip-label {
    flex: 1;

    bottom: 10%;
    right: 10%;

    text-align: right;
    font-size: 1.2vw;
    color: white;

    pointer-events: all;
    cursor: pointer;
}

.bottleVideoContainer {
	position: absolute;
	height: 100%;
	width: 100%;

    overflow: hidden;
}

.bottleVideo {
    position: absolute;
	aspect-ratio: 2736/1824;

	transform-origin: center center;
}

.c-step3-popup {
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0%;
    z-index: 100;

    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.9);

    font-size: 2vw;
    letter-spacing: .2rem;
}

.c-step3-popupContent {
    position: absolute;
    height: 15%;

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.c-step3-popupText {
    display: block;
}

.c-step3-popupSmellButton {
    display: block;
    position: absolute;
    width: 80%;
    height: 50%;

    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 0 auto;

    text-align: center;
    border: 1px solid white;
    font-size: 1.4vw;
}

.c-step3-popupSmellButtonInside {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;

    left: 50%;
    transform: translateX(-50%);

    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.arrow {
    flex: 1;

    margin-right: 1.2em;

    background-image: url('./../../style/img/icons/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;

    background-position: 100% center;
}