.language {
    position: absolute;
    width: 100%;
    height: 100%;

    margin: 0%;

    background-color: black;
    background-image: url('./../../style/img/backgrounds/languages.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

    font-family: 'Hennessy';
    font-size: 1vw;
    letter-spacing: .2rem;

    z-index: 200;
}

.language-logo {
    position: absolute;
	width: 40%;
	height: 20%;

    top: 15%;
    left: calc(50% - 20%); /* center - (width/2) */
    margin: 0 auto 0 auto;

	background-image: url('./../../style/img/logoHennessy_white.png');
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.language-langContainer {
    position: absolute;
    top: 50%;
    width: 70%;

    margin: auto 15% auto 15%;
}

.language-langButton {
    display:inline-block;
    width: calc(20% - 2px);

    margin: auto 2.5% auto 2.5%;

    border: 1px solid white;

    text-align: center;
}

.language-langButtonContent {
    display:inline-block;

    margin: 15% 10%;

    color: white;
}